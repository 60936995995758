import React, { useState } from "react";
import HintsList from "./HintsList";
import SearchInput from "./SearchInput";

export function SearchHandler({ CustomRouter }) {
    const [isActive, setIsActive] = useState(false);
    const [restoreHints, setRestoreHints] = useState(() => () => {}); // Инициализация пустой функции

    return (
        <>
            <SearchInput CustomRouter={CustomRouter} setIsActive={setIsActive} restoreHints={restoreHints} />
            <HintsList CustomRouter={CustomRouter} isActive={isActive} setIsActive={setIsActive} setRestoreHints={setRestoreHints} />
        </>
    );
}

