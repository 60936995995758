import React, { useState, useEffect, useRef } from 'react';
import { SearchIcon } from '../../appearance/icons/search_icon';
import { hintsService } from './hintsService';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from './helper';



const getSearchHistory = () => {
    return JSON.parse(localStorage.getItem(SEARCH_HISTORY_KEY)) || [];
};

const saveSearchHistory = (query) => {
    if (!query || query.length < 3) return;

    let history = getSearchHistory();
    history = [query, ...history.filter(item => item !== query)];
    if (history.length > MAX_HISTORY) {
        history = history.slice(0, MAX_HISTORY);
    }

    localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(history));
};

const SearchInput = ({ CustomRouter, setIsActive, restoreHints }) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchListLength, setSearchListLength] = useState(0);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        setIsActive(isFocused || searchListLength > 0);
    }, [isFocused, searchListLength, setIsActive]);

    const handleKeyDown = async (e) => {
        if (e.key === "Enter" && searchValue.length >= 3) {
            hintsService.setSearchValue(searchValue);

            // Ожидаем завершения асинхронных операций
            await hintsService.saveSearchHistory(searchValue);
            await hintsService.storeSearchQuery(searchValue);

            // Выполняем редирект после завершения операций
            window.location.href = `/search?q=${searchValue}&offset=0&limit=5`;
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        hintsService.setSearchValue(value);
    };

    useEffect(() => {
        const changeSearchListLength = (hintsList) => {
            setSearchListLength(hintsList.length);
        };
        hintsService.registerHintsObserver(changeSearchListLength);
        return () => hintsService.unRegisterHintsObserver(changeSearchListLength);
    }, []);

    const handleSearchInputClick = (e) => {
        e.stopPropagation(); // Не убираем фокус при клике на инпут
    };

    const handleBlur = (e) => {
        if (!e.relatedTarget || !e.relatedTarget.closest('.search-options__list')) {
            setIsFocused(false);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
        if (searchValue.length >= 3) {
            restoreHints(); // Восстанавливаем подсказки без запроса
        }
    };

    return (
        <label className="menu-common__search menu-middle__search gg1243" onKeyDown={handleKeyDown}>
            <SearchIcon />
            <input
                className={`menu-common__search-input menu-middle__search-input ${isFocused || searchListLength ? 'menu-common__search-input_active menu-middle__search-input_active' : ''}`}
                placeholder="Поиск по парт-номеру и параметрам..."
                value={searchValue}
                onClick={handleSearchInputClick}
                onChange={handleInputChange}
                onFocus={handleFocus} // Восстанавливаем подсказки при фокусе
                onBlur={handleBlur}
            />
        </label>
    );
};

export default SearchInput;
