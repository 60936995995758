import React, { useEffect, useState, useRef } from 'react';
import { hintsService } from './hintsService';
import HintItem from './HintItem';

const HintsList = ({ CustomRouter, isActive, setIsActive, setRestoreHints }) => {
    const [hintsList, setHintsList] = useState([]);
    const [searchHistory, setSearchHistory] = useState([]);
    const [globalSearchHistory, setGlobalSearchHistory] = useState([]);
    const [loading, setLoading] = useState(true); // Стейт загрузки
    const [isReady, setIsReady] = useState(false); // Флаг готовности компонента
    const hintsCache = useRef([]); // Кэш подсказок
    const listRef = useRef();

    const resetScroll = () => {
        listRef.current && listRef.current.scrollTo(0, 0);
    };

    useEffect(() => {
        const fetchData = async () => {
            // Получаем локальную историю
            setSearchHistory(hintsService.getSearchHistory());
            
            // Запускаем асинхронную загрузку глобальной истории
            hintsService.fetchGlobalSearchHistory();
        };

        fetchData();

        // Подписка на обновление подсказок
        hintsService.registerHintsObserver((hints) => {
            setHintsList(hints);
            hintsCache.current = hints;
        });

        // Callback для обновления глобальной истории после её загрузки
        const globalHistoryObserver = (globalHistory) => {
            setGlobalSearchHistory(globalHistory);
            setIsReady(true); // Флаг, что данные готовы
        };

        // Подписываемся на обновление глобальной истории
        hintsService.registerGlobalHistoryObserver(globalHistoryObserver);

        return () => {
            hintsService.unRegisterHintsObserver(setHintsList);
            hintsService.unRegisterGlobalHistoryObserver(globalHistoryObserver);
        };
    }, []);

    useEffect(() => {
        if (typeof setRestoreHints === "function") {
            setRestoreHints(() => () => setHintsList(hintsCache.current));
        }
    }, [setRestoreHints]);

    const currentSearchValue = hintsService.getSearchValue();

    const handleListClick = (e) => {
        e.stopPropagation();
        setIsActive(true);
    };

    const handleListMouseDown = (e) => {
        e.preventDefault();
    };

    // Проверяем, завершена ли загрузка и данные готовы
    if (!isReady) {
        return <div>Loading...</div>;  // Показать индикатор загрузки, если данные еще не загружены
    }

    return (
        <ul
            className={`search-options__list chapche-event-scroll ${isActive ? "search-options__list_active" : ""}`}
            ref={listRef}
            onClick={handleListClick}
            onMouseDown={handleListMouseDown}
            tabIndex="-1"
        >
            {searchHistory.length > 0 && (
                <>
                    <li className="search-history__title">ранее вы искали</li>
                    {searchHistory.map((query, index) => (
                        <li 
                            className="search-history__item"
                            key={index}
                            tabIndex="0"
                            onClick={() => {
                                hintsService.setSearchValue(query);
                                window.location.href = `/search?q=${query}&offset=0&limit=5`;
                            }}
                        >
                            {query}
                        </li>
                    ))}
                </>
            )}

            {globalSearchHistory.length > 0 && (
                <>
                    <li className="search-history__title">также ищут</li>
                    {globalSearchHistory.map((query, index) => (
                        <li 
                            className="search-history__item"
                            key={`global-${index}`}
                            tabIndex="0"
                            onClick={() => {
                                hintsService.setSearchValue(query);
                                window.location.href = `/search?q=${query}&offset=0&limit=5`;
                            }}
                        >
                            {query}
                        </li>
                    ))}
                </>
            )}

            {currentSearchValue.length >= 3 && hintsList.length > 0 && (
                <>
                    <li className="search-history__title">быстрый поиск</li>
                    {hintsList.map((item, index) => (
                        <HintItem
                            key={item.productId + index}
                            productId={item.productId}
                            productPartNumber={item.productPartNumber}
                            productManufacturerId={item.productManufacturerId}
                            productManufacturerName={item.productManufacturerName}
                            productAttributeValue={item.productAttributeValue}
                            productAttributeKey={item.productAttributeKey}
                            CustomRouter={CustomRouter}
                        />
                    ))}
                    <li className="search-options__list-item search-options__list-item--show-all">
                        <button
                            onClick={async () => {
                                setIsActive(false);

                                try {
                                    await hintsService.saveSearchHistory(currentSearchValue);
                                    await hintsService.storeSearchQuery(currentSearchValue);

                                   
                                    window.location.href = `/search?q=${currentSearchValue}&offset=0&limit=5`;
                                } catch (error) {
                                    console.error("Ошибка при обработке запроса:", error);
                                }
                            }}


                            className="search-options__show-all-button"
                        >
                            Показать все результаты
                        </button>
                    </li>
                </>
            )}
        </ul>
    );
};

export default HintsList;
